.navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-item {
  text-decoration: none;
  color: #ffffff;
}

.nav-item:hover,
.active {
  color: #000000;
}
