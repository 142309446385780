@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

@font-face {
  font-family: "InriaSerifBold";
  src: local("InriaSerifBold"),
    url("./fonts/Inria_Serif/InriaSerif-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "InriaSerifRegular";
  src: local("InriaSerifRegular"),
    url("./fonts/Inria_Serif/InriaSerif-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "DMSerifDisplayRegular";
  src: local("DMSerifDisplayRegular"),
    url("./fonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf")
      format("truetype");
  font-weight: normal;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
