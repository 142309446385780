div {
    background-color: black;
}

h1 {
    color: white;
}



.marketplace {
    color: white;
    font-family: 'Playfair Display', serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.15rem;
  }

.marketplace-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  

.sell-buy-icon, .fees-icon, .college-icon {
    font-size: 2rem;
  }

  .marketplace-link {
    font-size: 2rem;
    text-decoration: none;
    color: #7DF9FF;
  }
  
  .marketplace-link:hover {
    text-decoration: none;
    color: inherit;
  }