body {
    font-family: Arial, sans-serif;
  }

  text {
    color: #7DF9FF;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 20px; */
    margin-bottom: 60px;
  }
  
  form {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    color:#7DF9FF;
  }

  .listing {
    color:#7DF9FF;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  textarea {
    resize: vertical;
  }
  
  button {
    /* background-color: white; */
    background-color: black;
    border: none;
    color: #7DF9FF;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 4px;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  input.input-error,
  textarea.input-error {
    border: 2px solid red !important;
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 200px;
    display: block;
    margin-bottom: 20px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  @media screen and (max-width: 767px) {
    form {
      padding: 0 10px;
    }
  }
  