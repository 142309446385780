text {
  color: #7df9ff;
}
.item-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 24px;
}

.item-cell {
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 12px rgba(0, 0, 0, 0.2); */
  box-shadow: 0px 0px 10px #7df9ff;
  color: #7df9ff;
}

.item-cell img {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 16px;
  border-radius: 6px;
}

body.blur {
  filter: blur(5px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: black;
  padding: 8px;
  border-radius: 5px;
  background-color: white;
  backdrop-filter: none;

  width: 90%;
}

.popup-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
}

.popup-content label {
  margin-top: 10px;
}

.popup-content input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
}
