
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  /* background-color: white; */
  background-color: black;
  font-size: 24px;
  font-weight: bold;
}

.uni {
  color: #ff6700;
  /* color: #7DF9FF; */
}

.swap {
  /* color: #004E98; */
  color: #7DF9FF;
}

.app-container {
  margin: 20px;
}
