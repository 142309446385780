div {
  /* background-color: white; */
  background-color: black;
}

.disclaimer {
  font-size: 12px;
  color: gray;
  margin-top: 20px;
  text-align: center;
}

.custom-input-container {
  position: relative;
  width: 290px;
  height: 10px;
  background-color: transparent;
}

.custom-input {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 24px; /* adjust as per your needs */
  border: none;
  background: transparent;
  padding-left: 10px; /* adjust as per your needs */
  transform: translateY(-50%);
  outline: none;
  font-size: 15px; /* adjust as per your needs */
}

.input-error {
  border: 2px solid red;
}
